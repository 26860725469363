import { Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import HeaderLogo from '@/components//HeaderLogo/HeaderLogo';
import HeaderBasket from '@/components/HeaderBasket/HeaderBasket';
import LanguagePagePicker from '@/components/LanguagePagePicker/LanguagePagePicker';
import PageMargins from '@/components/ui/PageMargins/PageMargins';

import { InnerWrapper, OuterWrapper } from './LowerBar.style';

const UpperBar = ({
  hasNavigation,
  languagePagePickerHidden,
  basketHidden,
  actionIcons,
}) => {
  return (
    <OuterWrapper bgcolor="common.white" hasNavigation={hasNavigation}>
      <PageMargins>
        <InnerWrapper
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          hasNavigation={hasNavigation}>
          <HeaderLogo data-cy="link: header logo" />
          <Stack direction="row" alignItems="center">
            {actionIcons}
            {!languagePagePickerHidden && <LanguagePagePicker />}
            {!basketHidden && (
              <>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: 20,
                    mx: 1,
                  }}
                />
                <HeaderBasket data-cy="headerBasket desktop" />
              </>
            )}
          </Stack>
        </InnerWrapper>
      </PageMargins>
    </OuterWrapper>
  );
};

UpperBar.propTypes = {
  hasNavigation: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  basketHidden: PropTypes.bool,
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

UpperBar.defaultProps = {
  hasNavigation: true,
  languagePagePickerHidden: false,
  basketHidden: false,
  actionIcons: null,
};
export default UpperBar;
