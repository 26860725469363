import { Box, Divider, Slide } from '@mui/material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import CountdownBanner from '@/components/CountdownBanner/CountdownBanner';
import HeaderBasket from '@/components/HeaderBasket/HeaderBasket';
import HeaderEmail from '@/components/HeaderEmail/HeaderEmail';
import IntlHeaderEmail from '@/components/IntlHeaderEmail/IntlHeaderEmail';
import LanguagePagePicker from '@/components/LanguagePagePicker/LanguagePagePicker';
import PhoneOutageBanner from '@/components/PhoneOutageBanner/PhoneOutageBanner';
import Dialog from '@/components/ui/Dialog/Dialog';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import getConfig from '@/configs/global';
import { internationalPagesPaths } from '@/constants/internationalPages';
import { trackHeaderPhoneClick } from '@/helpers/dataLayer';
import { formatPhoneToLink } from '@/helpers/utils';
import usePhoneNumber from '@/hooks/usePhoneNumber';

import {
  HeaderLogo,
  IconsWrapper,
  LogoRow,
  PhoneNumber,
  Wrapper,
} from './MobileHeader.style';
import MobileNavigation from './MobileNavigation/MobileNavigation';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" unmountOnExit ref={ref} {...props} />;
});

const MobileHeader = ({
  navigation,
  basketHidden,
  languagePagePickerHidden,
  actionIcons,
  phoneNumber: phone,
  mrktClassNamePhone,
  mrktClassNameMail,
}) => {
  const [phoneNumber, phoneNumberLink] = usePhoneNumber();
  const [mobileUnfolded, setMobileUnfolded] = useState(false);
  const [navbarHeight, setNavbarHeight] = useState(0);
  const { pathname } = useRouter();
  const wrapperRef = useRef();
  const navbarHeightRef = useRef(navbarHeight);

  const {
    publicRuntimeConfig: {
      FEAT_FLAG_PHONE_OUTAGE,
      FEAT_FLAG_HIDE_PHONE_NUMBER,
    },
  } = getConfig();

  const toggleFold = () => {
    setMobileUnfolded(prevState => !prevState);
  };

  const closeFold = () => setMobileUnfolded(false);

  useEffect(() => {
    navbarHeightRef.current = navbarHeight;
  }, [navbarHeight]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      if (entry.contentRect.height !== navbarHeightRef.current) {
        setNavbarHeight(entry.contentRect.height);
      }
    });

    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (mobileUnfolded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [mobileUnfolded]);

  return (
    <Wrapper data-cy="page header mobile">
      <Box ref={wrapperRef}>
        <CountdownBanner />
        {FEAT_FLAG_PHONE_OUTAGE === true && <PhoneOutageBanner />}
        {!FEAT_FLAG_HIDE_PHONE_NUMBER && (
          <PhoneNumber
            component="a"
            variant="body2"
            href={phone ? formatPhoneToLink(phone) : phoneNumberLink}
            data-cy="link: phone mobile"
            className={mrktClassNamePhone || 'mrkt-hp_phone'}
            onClick={trackHeaderPhoneClick}>
            <SvgIcon name="phone" color="primary" sx={{ marginRight: 1 }} />
            <span className="mrsCallTracking">{phone || phoneNumber}</span>
          </PhoneNumber>
        )}
        <LogoRow>
          <IconsWrapper
            direction="row"
            autoWidth={
              !(languagePagePickerHidden || basketHidden || !navigation)
            }>
            {navigation && (
              <SvgIcon
                data-cy="button: burger"
                name={mobileUnfolded ? 'close' : 'menu'}
                onClick={toggleFold}
                color="primary"
              />
            )}
          </IconsWrapper>
          <HeaderLogo onClick={closeFold} data-cy="link: header logo mobile" />
          <IconsWrapper
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
            autoWidth={
              !(languagePagePickerHidden || basketHidden || !navigation)
            }>
            {actionIcons}
            {!languagePagePickerHidden && <LanguagePagePicker />}
            {!basketHidden && (
              <>
                <HeaderBasket data-cy="headerBasket mobile" />
                <Divider orientation="vertical" sx={{ height: 24 }} />
              </>
            )}
            {internationalPagesPaths.includes(pathname) ? (
              <IntlHeaderEmail mrktClassName={mrktClassNameMail} />
            ) : (
              <HeaderEmail
                data-cy="link: email mobile"
                mrktClassName={mrktClassNameMail}
              />
            )}
          </IconsWrapper>
        </LogoRow>
      </Box>
      {navigation && (
        <Dialog
          disableScrollLock
          open={mobileUnfolded}
          fullScreen
          TransitionComponent={Transition}
          hideBackdrop
          PaperProps={{
            sx: {
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            },
          }}
          sx={{
            top: `${navbarHeight}px !important`,
          }}>
          <MobileNavigation
            closeNavigation={() => setMobileUnfolded(false)}
            navigation={navigation}
          />
        </Dialog>
      )}
    </Wrapper>
  );
};

MobileHeader.propTypes = {
  /** Should hide basket */
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  /** Adds additional icons in front of the default ones */
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* Overwrites default phone number */
  phoneNumber: PropTypes.string,
  /* Class names used for tracking */
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

MobileHeader.defaultProps = {
  basketHidden: false,
  languagePagePickerHidden: false,
  navigationHidden: false,
  actionIcons: null,
  phoneNumber: null,
  mrktClassNamePhone: null,
  mrktClassNameMail: null,
};

export default MobileHeader;
