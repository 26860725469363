import PropTypes from 'prop-types';
import React from 'react';

import Link from '../Link/Link';
import MenuItem from '../MenuItem/MenuItem';
import { Level, Links } from '../MobileNavigation.style';

const Level1 = ({
  menuItems,
  navbarLinks,
  activeLevel,
  onClick,
  closeNavigation,
}) => {
  return (
    <Level>
      <Links active={activeLevel === 1}>
        {menuItems.map((link, index) => (
          <MenuItem
            item={link}
            onClick={onClick}
            closeNavigation={closeNavigation}
            key={index}
          />
        ))}
        {navbarLinks.map((item, index) => (
          <Link
            key={index}
            href={item.attributes.link}
            title={item.attributes.title}
            closeNavigation={closeNavigation}
            openInNewTab={item.attributes.openInNewTab}
          />
        ))}
      </Links>
    </Level>
  );
};

Level1.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  navbarLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  activeLevel: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  closeNavigation: PropTypes.func.isRequired,
};

export default Level1;
